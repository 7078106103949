/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // WOW
        
        new WOW().init();
        
        // Responsive Nav
        
				var nav = responsiveNav(".nav-collapse", { // Selector
					animate: true, // Boolean: Use CSS3 transitions, true or false
					transition: 284, // Integer: Speed of the transition, in milliseconds
					label: "Menu", // String: Label for the navigation toggle
					insert: "before", // String: Insert the toggle before or after the navigation
					customToggle: ".lw-nav-toggle", // Selector: Specify the ID of a custom toggle
					closeOnNavClick: false, // Boolean: Close the navigation when one of the links are clicked
					openPos: "relative", // String: Position of the opened nav, relative or static
					navClass: "nav-collapse", // String: Default CSS class. If changed, you need to edit the CSS too!
					navActiveClass: "js-nav-active", // String: Class that is added to <html> element when nav is active
					jsClass: "js", // String: 'JS enabled' class which is added to <html> element
					init: function(){}, // Function: Init callback
					open: function(){}, // Function: Open callback
					close: function(){} // Function: Close callback
				});
				
				// slimMenu
				$('#menu-mobile-main-menu').slimmenu({
			    resizeWidth: '767',
			    collapserTitle: 'Main Menu',
			    animSpeed: 'medium',
			    easingEffect: null,
			    indentChildren: false,
			    childrenIndenter: '&nbsp;',
			    expandIcon:	'<i class="fa" aria-hidden="true">&#xf067;<i>',
			    collapseIcon:	'<i class="fa" aria-hidden="true">&#xf068;<i>'
				});
				
				// OwlCarousel
				
				$('.lw-team-carousel').owlCarousel({
					items: 1,
					loop: true,
					autoplay: true,
					animateIn: 'fadeIn',
					animateOut: 'fadeOut',
					autoplayTimeout: 8000,
					mouseDrag: false,
					dots: false,
					nav: true,
					navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
					lazyContent: true
				});
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
				
				// OwlCarousel
				
				$('.owl-carousel').owlCarousel({
					items: 1,
					loop: true,
					autoplay: true,
					animateIn: 'fadeIn',
					animateOut: 'fadeOut',
					autoplayTimeout: 8000,
					mouseDrag: false,
					dots: false,
					nav: true,
					navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
					lazyContent: true
				});		
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.